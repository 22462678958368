<template>
  <users-list />
</template>

<script>
import UsersList from '@/components/organisms/shared/users/UsersList';

export default {
  name: 'DashboardSSPUsersList',
  components: { UsersList },
};
</script>
